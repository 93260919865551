








import Vue from 'vue';
import Component from 'vue-class-component';
import { Global } from '@/store';

@Component({})
export default class About extends Vue {
    version_hash = '##examplehash##';
    version_num = 'v-2.66.10';
    // version_num = '2.65.12';    
    /* erez put release number */

    @Global.Mutation('setPageTitle') setPageTitle;

    mounted() {
        this.setPageTitle('About');
    }
}
